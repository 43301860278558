@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  height: 100vh;
  background: #131313;
  overflow-y: scroll;
  scroll-behavior: smooth; /* Enable smooth scrolling behavior */
}

.hero-back{
  background:
          radial-gradient(55.42% 64.28% at 86.96% 73.84%, rgba(255, 255, 255, 0.112) 12.39%, rgba(34, 34, 34, 0.2) 100%),
          linear-gradient(0deg, #222222, #222222);
}

code {
  font-family: Inter, sans-serif;
  color: rgba(255, 255, 255, 1);
}

.header-wrapper{
  display: flex;
  gap: 24px;
}

.header-mob-wrapper{
  display: none;
}

.footer-info-block{
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.container{
  max-width: 1224px;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scroll-behavior: smooth; /* Enable smooth scrolling behavior */
}

.footer-main-wrapper{
  padding-top: 120px;
  padding-bottom: 120px;
}

.px-6{
  padding: 0;
}

a{
  color: white;
  cursor: pointer;
}

.insta-wrapper{
  padding-left: 40px;
}

.footer-end{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 144px;
  gap: 40px;
  height: 100%;
  align-items: flex-end;
}

.footer-links{
  justify-content: end;
  width: 100%;
  text-align: right;
}

.about-content{
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
}

.start-padding{
  padding-top: 60px;
}

.main-mob-wrapper{
  margin-bottom: 0;
}

.input-text-color{
  color: white!important;
  font-weight: 300;
}

.input-main{
  color: white!important;
  background: #7676803D!important;
}

.wrapper-content{
  padding: 10px;
  color:white!important;
}

.wrapper-content:focus{
  background:none!important;
}

.wrapper{
  background: #3B3B3BFF!important;
}

.base-wrapper{
  color: white!important;
  background: none!important;
  padding: 0!important;
}

@media (max-width: 600px) {
  .main-mob-wrapper{
    margin-bottom: 60px;
  }
  .start-padding{
    padding-top: 0;
  }
  .about-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-links{
    text-align: center;
    justify-content: center;
  }
  .footer-links{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-end{
    align-items: flex-start;
    flex-direction: column;
    margin-top: 60px;
  }
  .insta-wrapper{
    padding-left: 0;
  }
  .footer-info-block{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .footer-main-wrapper{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .header-wrapper{
    display: none;
  }
  .header-mob-wrapper{
    display: flex;
  }
}

.card-back{
  background:
          linear-gradient(0deg, #131313, #131313),
          radial-gradient(66.58% 47.78% at 74.03% 81.27%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}
